/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, createContext, useContext, FC, ReactNode, useCallback } from 'react';
import decode from 'jwt-decode';
import { FullPageSpinner } from '../components/primitives';
import { save_token, clear_store, get_token } from '../utils/CookieStore';
import { GQLClient } from './ApolloClientProvider';
import { FETCH_USER_QUERY } from './queries';
import { useRouter } from 'next/router';
import React from 'react';
const AuthContext = createContext({});
interface Props {
  children: ReactNode;
}
interface DecodedData {
  id: string;
}
const AuthProvider: FC<Props> = ({
  children
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [user, setUser] = useState(null);
  const router = useRouter();
  const client = GQLClient();
  const succeedLogin = async (token: string, returnUrl?: string, disableRoute = false) => {
    save_token(token);
    const {
      id
    } = decode(token as string) as DecodedData;
    const _user = await fetchUser(id);
    setUser(_user);
    setIsLoggedIn(true);
    !disableRoute && window.location.assign(returnUrl && !!returnUrl?.length ? returnUrl : '/account/home');
  };
  const succeedVerification = async (token: string) => {
    save_token(token);
    decode(token);
  };
  const logout = useCallback((changeUrl = true) => {
    clear_store();
    setIsLoggedIn(false);
    setUser(null);
    const returnUrl = router.asPath;
    changeUrl && router.push(router.asPath.includes('account') ? `/login?returnUrl=${encodeURIComponent(returnUrl)}` : '/');
  }, [router]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchUser = async (id: string) => {
    const response = await client.query({
      query: FETCH_USER_QUERY,
      variables: {
        user_id: id
      }
    });
    return response?.data?.user?.[0];
  };
  const validateUser = useCallback(async () => {
    setIsValidating(true);
    try {
      const token: string | number | true | object | null = get_token() || null;
      const {
        id
      } = decode(token as string) as DecodedData;
      const _user = await fetchUser(id);
      setUser(_user);
      setIsLoggedIn(true);
    } catch (e) {
      logout();
    }
    setIsValidating(false);
  }, [fetchUser, logout]);
  useEffect(() => {
    const token = get_token();
    if (token) {
      validateUser();
    } else if (router.route.includes('account')) {
      logout();
    }
  }, [router.pathname]);
  useEffect(() => {
    if (user && !router.route.includes('/account/')) {
      router.push('/account/home');
    }
  }, []);

  // router, user

  if (isValidating) return <FullPageSpinner />;
  return <AuthContext.Provider value={{
    user,
    setUser,
    isLoggedIn,
    logout,
    validateUser,
    succeedLogin,
    succeedVerification
  }} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="AuthContext.tsx">
      {children}
    </AuthContext.Provider>;
};
const useAuth = () => useContext(AuthContext);
export { AuthProvider, useAuth };