import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
/* eslint-disable @next/next/no-img-element */
import React, { RefObject } from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { getFaintBgByPartner } from './partner-config';
import { getLandingImageByPartner } from '../../utils/partnerTheme';
const Footer = dynamic(() => import('./footer'));
const Navbar = dynamic(() => import('./navbar'));
const Faq = dynamic(() => import('../../pages/faq'));
const RequestFixPage = dynamic(() => import('../../pages/request-fix'));
const DownloadApp = dynamic(() => import('../../components/download-app/download-app'));
const lpViews = process.env.NEXT_PUBLIC_LP_VIEWS;
const UkLanding = () => {
  const buyPlanRef = React.useRef<HTMLDivElement>(null);
  const requestFixRef = React.useRef<HTMLDivElement>(null);
  const faqRef = React.useRef<HTMLDivElement>(null);
  const handleNavigate = (ref: RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({
      behavior: 'smooth'
    });
  };
  return <Box className="body" data-sentry-element="Box" data-sentry-component="UkLanding" data-sentry-source-file="uk-landing.tsx">
      {/* navbar */}
      <Navbar navigate={handleNavigate} buyPlanRef={buyPlanRef} requestFixRef={requestFixRef} data-sentry-element="Navbar" data-sentry-source-file="uk-landing.tsx" />
      {/* section 1 */}
      {lpViews?.includes('one') && <Box as="section" className="section main-section" backgroundColor="#fff">
          <Box className="container---main">
            <Box className="boxed-image-section increase-bottom-padding" pb={0} maxW="1400px" mx="auto" bg={getFaintBgByPartner()}>
              <Box className="boxed-image-section-content-wrapper">
                <Box className="container---m" ml={['unset', 'unset', '50px']} pr={['initial', '100px']}>
                  <Box className="hero-cta-contents">
                    <Box className="large-cta-box-text align-left">
                      <Heading className="display-heading hero-cta-heading" fontSize={['54px', '80px', '87px']} lineHeight={['0.95em']}>
                        Get All Your <br />
                        Devices Fixed.
                      </Heading>
                      <Box className="container---s"></Box>
                    </Box>
                    <Box className="text-block-21">
                      From manufacturer-approved repairs of your damaged devices
                      to our Device Protection Plans. We&#x27;ve got you
                      covered!
                    </Box>
                    <Box className="horizontal-buttons">
                      <Link href="/request-fix/category" className="button w-inline-block" style={{
                    backgroundColor: '#0F61D6'
                  }}>
                        <Box className="button-text text-primary-1b">
                          Request fix
                        </Box>
                      </Link>
                      {/* <Link href="/device-care/plan">
                        <Box className="button-text protect-button">
                          Protect my device
                        </Box>
                       </Link> */}
                    </Box>
                    <Box className="app-store-reviews-horizontal stack-on-mobile">
                      <Box className="app-store-reviews-text centered-on-mobile">
                        <Box className="stars" mx="auto">
                          <img src="images/5-star.webp" loading="lazy" style={{
                        width: 200
                      }} alt="5 stars"
                      // className="image-7"
                      />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <img src={getLandingImageByPartner().src} alt="landing" className="boxed-image-section-image landing-image" />
            </Box>
          </Box>
        </Box>}
      {/* section 2 */}

      {lpViews?.includes('two') && <Box as="section" className="section repair-section" maxW="1400px" mx="auto">
          <Box className="container---main">
            <Box className="product-features-section">
              <Heading className="heading-one text-center" fontWeight="700" mb={10}>
                Device Repair from your comfort zone{' '}
              </Heading>
              <Box className="product-features-grid">
                <Box id="w-node-_39964da8-3045-47d6-292c-3b3e4d7a10ea-df636a8f" className="product-features-column">
                  <Box data-w-id="c9a3038f-81c6-96c5-9bf5-ee6d59d674bc" className="product-feature-box" bg="#0F61D699">
                    <Box className="widget transaction">
                      <Box className="widget-transaction-title">
                        <Box className="text-bold">Scheduled Pickup</Box>
                      </Box>
                      <Box className="widget pay-widget">
                        <Box className="widget-transaction-recipient">
                          <Image src="images/images.jpeg" alt="" className="widget-transaction-author" borderRadius={4} />
                          <Box className="text-block-45">
                            Apple iPhone 13 <br />
                            Broken Screen
                          </Box>
                        </Box>
                        <Box className="widget-pay-text">
                          <Box className="muted">Pickup Time</Box>
                          <Box className="heading-three">
                            Tue 3rd March
                            <br />
                            2:00 pm
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="container---xs">
                      <Box className="product-feature-box-text">
                        <Box className="heading-three">Request Repairs</Box>
                        <Box className="text-block-17">
                          Manufacturer-Approved fast, affordable and reliable
                          repairs of your devices from the comfort of your home,
                          office, and wherever you are.
                        </Box>
                        <Link href="/request-fix/category" className="button  w-inline-block" style={{
                      backgroundColor: '#0F61D6'
                    }}>
                          <Box className="button-text text-primary-1b">
                            Request a fix
                          </Box>
                          <Box className="button-effect bg-white"></Box>
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box id="w-node-_91215528-acc9-924a-2663-2a8ed490a256-df636a8f" className="product-features-column offset">
                  <Image src="/images/uk-section-2.webp" alt="section image" />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>}

      {/* section 3 */}
      {lpViews?.includes('three') && <Box as="section" className="section repair-section " bg={['unset', '#263238']} color={['#263238', 'white']} mx="auto">
          <Box className="container---main">
            <Flex gap={['15px', '60px']} flexDir={['column', 'row']} justifyContent={['space-between']} maxW="1428px" px={[0, 4]} mx="auto">
              <Box flex="1">
                <Text className="heading-four" mb={2} color={['black', 'white']}>
                  Get it fixed fast
                </Text>
                <Text className="text-block-8">
                  Quickest pick up,repair and delivery service in the country!
                </Text>
              </Box>
              <Box flex="1">
                <Text className="heading-four" mb={2} color={['black', 'white']}>
                  Repair Warranty
                </Text>
                <Text className="text-block-9">
                  Manufacturer Approved Warranty Repairs for your devices!
                </Text>
              </Box>
              <Box flex="1">
                <Text className="heading-four" mb={2} color={['black', 'white']}>
                  Affordable Options
                </Text>
                <Text>
                  Fix your broken devices without breaking your bank account!
                </Text>
              </Box>
              <Box flex="1">
                <Text className="heading-four" mb={2} color={['black', 'white']}>
                  Convenient Repairs
                </Text>
                <Text>
                  World leading Door-to-Door service! Every service you need at
                  your fingertips through our app!
                </Text>
              </Box>
            </Flex>
          </Box>
        </Box>}
      {/* section 4 */}

      {/* buy plan */}
      {/* <ProtectionPlan sectionRef={buyPlanRef} /> */}
      {/* request fix */}
      <RequestFixPage sectionRef={requestFixRef} data-sentry-element="RequestFixPage" data-sentry-source-file="uk-landing.tsx" />

      <DownloadApp data-sentry-element="DownloadApp" data-sentry-source-file="uk-landing.tsx" />
      {/* faq */}
      <Faq sectionRef={faqRef} data-sentry-element="Faq" data-sentry-source-file="uk-landing.tsx" />
      {/* footer */}
      <Footer navigate={handleNavigate} buyPlanRef={buyPlanRef} requestFixRef={requestFixRef} faqRef={faqRef} data-sentry-element="Footer" data-sentry-source-file="uk-landing.tsx" />
    </Box>;
};
export default UkLanding;
export async function getServerSideProps() {
  return {
    props: {}
  };
}