import * as React from 'react';
import { Box, BoxProps, Spinner, SpinnerProps, Text } from '@chakra-ui/react';
export function FullPageSpinner({
  size,
  color,
  speed,
  label,
  thickness,
  emptyColor,
  placeholder,
  ...rest
}: BoxProps & SpinnerProps & {
  placeholder?: string;
}) {
  const spinnerProps = {
    label,
    speed,
    thickness,
    size,
    color,
    emptyColor
  };
  return <Box height="100vh" display="flex" alignItems="center" flexDirection="column" justifyContent="center" {...rest} data-sentry-element="Box" data-sentry-component="FullPageSpinner" data-sentry-source-file="FullPageSpinner.tsx">
      <Spinner {...spinnerProps} color="blue" data-sentry-element="Spinner" data-sentry-source-file="FullPageSpinner.tsx" />
      {placeholder && <Text mt="1rem">{placeholder}</Text>}
    </Box>;
}