import '../../styles/globals.css';
import '../../styles/custom-datepicker.css';
import 'react-international-phone/style.css';
import '../../styles/mymtnpicknfix.webflow.css';
import { getFaviconPathByPartner, getTitleByPartner } from '../utils/partnerTheme';
import ApolloClientProvider from '../contexts/ApolloClientProvider';
import type { AppProps } from 'next/app';
import { AuthProvider } from '../contexts/AuthContext';
import { BranchIoProvider } from '../contexts/branch-io-provider';
// import Bugsnag from '@bugsnag/js';
import { ChakraBaseProvider } from '@chakra-ui/react';
import ConsentScreen from '../components/ConsentScreen';
import CountryProvider from '../contexts/country-provider';
// import ErrorStateMsg from '../components/error-state-msg';
import Fonts from '../components/primitives/Fonts';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { GoogleTagManager } from '@next/third-parties/google';
import Head from 'next/head';
import { IntercomProvider } from 'react-use-intercom';
import React from 'react';
// import { cookieStorage } from '../utils/CookieStore';
import { theme } from '../components/pro-theme';

// import BugsnagPluginReact from '@bugsnag/plugin-react';
// import BugsnagPerformance from '@bugsnag/browser-performance';

const INTERCOM_APP_ID = 'nhkmwor5';

// Bugsnag.start({
//   apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || '',
//   appVersion: '0.1.0',
//   releaseStage: process.env.NEXT_PUBLIC_APP_ENV,
//   plugins: [new BugsnagPluginReact()],
// });
// BugsnagPerformance.start({
//   apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || '',
//   appVersion: '0.1.0',
// });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export default function App({
  Component,
  pageProps
}: AppProps) {
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <title>
          Comprehensive Device Insurance & Repair Services -{' '}
          {getTitleByPartner()}
        </title>
        <link rel="shortcut icon" href={`${getFaviconPathByPartner()}/favicon.ico`} type="image/x-icon" />
        <link rel="icon" href={`${getFaviconPathByPartner()}/favicon.ico`} />
        <link rel="manifest" href="/manifest.json" />

        <link rel="apple-touch-icon" sizes="180x180" href={`${getFaviconPathByPartner()}/apple-touch-icon.png`} />
        <link rel="icon" type="image/icon" sizes="32x32" href={`${getFaviconPathByPartner()}/favicon-32x32.png`} />
        <link rel="icon" type="image/icon" sizes="16x16" href={`${getFaviconPathByPartner()}/favicon-16x16.png`} />
        <link rel="manifest" href={`${getFaviconPathByPartner()}/site.webmanifest`} />
        <link rel="mask-icon" href={`${getFaviconPathByPartner()}/safari-pinned-tab.svg`} color="##ffcb05" />
        <meta name="msapplication-TileColor" content="##ffcb05" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="theme-color" content="#ffffff" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
      </Head>
      {/* <ErrorBoundary
        FallbackComponent={ErrorStateMsg}
        onError={(event: { originalError: Error }) => {
          const error = event.originalError;
           if (error instanceof Error) {
            const chunkFailedMessage = /Loading chunk [\d]+ failed/;
             if (error?.message && chunkFailedMessage.test(error.message)) {
              if (!cookieStorage.getWithExpiry('chunk_failed')) {
                cookieStorage.setWithExpiry('chunk_failed', 'true', 10000);
                window.location.reload();
              }
            }
          }
        }}
       > */}
      <GoogleAnalytics trackPageViews data-sentry-element="GoogleAnalytics" data-sentry-source-file="_app.tsx" />
      <GoogleTagManager gtmId="GTM-P7JGWCKP" data-sentry-element="GoogleTagManager" data-sentry-source-file="_app.tsx" />
      <GoogleTagManager gtmId="GTM-N2LLDZLQ" data-sentry-element="GoogleTagManager" data-sentry-source-file="_app.tsx" />
      <IntercomProvider appId={INTERCOM_APP_ID} data-sentry-element="IntercomProvider" data-sentry-source-file="_app.tsx">
        <ChakraBaseProvider theme={theme} data-sentry-element="ChakraBaseProvider" data-sentry-source-file="_app.tsx">
          <Fonts data-sentry-element="Fonts" data-sentry-source-file="_app.tsx" />
          <ApolloClientProvider data-sentry-element="ApolloClientProvider" data-sentry-source-file="_app.tsx">
            <CountryProvider data-sentry-element="CountryProvider" data-sentry-source-file="_app.tsx">
              <AuthProvider data-sentry-element="AuthProvider" data-sentry-source-file="_app.tsx">
                <BranchIoProvider data-sentry-element="BranchIoProvider" data-sentry-source-file="_app.tsx">
                  <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
                </BranchIoProvider>
                <ConsentScreen data-sentry-element="ConsentScreen" data-sentry-source-file="_app.tsx" />
              </AuthProvider>
            </CountryProvider>
          </ApolloClientProvider>
        </ChakraBaseProvider>
      </IntercomProvider>
      {/* </ErrorBoundary> */}
    </>;
}