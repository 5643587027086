import { Flex } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { FullPageSpinner } from '../components/primitives';
import { getCountryIsoByPartner } from '../utils/partnerTheme';
type TCountry = {
  id: string;
  iso_code_3: string;
  ison_code_2: string;
  name: string;
  phone_code: string;
  currency_code: string;
};
const CountryContext = React.createContext<{
  countries?: TCountry[];
  selectedCountry?: TCountry;
}>({});
interface Props {
  children: React.ReactNode;
}
const FETCH_COUNTRIES = gql`
  query fetchCountries {
    country {
      currency_code
      id
      iso_code_2
      iso_code_3
      name
      phone_code
    }
  }
`;
const CountryProvider = ({
  children
}: Props) => {
  const {
    data,
    loading
  } = useQuery(FETCH_COUNTRIES);
  if (loading) {
    return <Flex align="center" justify="center" w="full" h="100svh">
        <FullPageSpinner />
      </Flex>;
  }
  return <CountryContext.Provider value={{
    countries: data?.country,
    selectedCountry: data?.country?.find((_country: TCountry) => _country?.iso_code_3 === getCountryIsoByPartner())
  }} data-sentry-element="unknown" data-sentry-component="CountryProvider" data-sentry-source-file="country-provider.tsx">
      {children}
    </CountryContext.Provider>;
};
export const useCountries = () => useContext(CountryContext);
export default CountryProvider;