import { isPNF, isUK } from '../utils/partnerTheme';
import IndexPage from '../components/homepage';
import MetaHead from '../components/meta-head';
import React from 'react';
import TrialLanding from '../components/homepage/trial-campaign';
import UkLanding from '../components/homepage/uk-landing';
export const metadata = {
  title: 'Picknfix website',
  description: '...'
};
function index() {
  return <>
      {isUK ? <MetaHead title="Comprehensive Repair Services - PicknFix" description="Repair your devices with PicknFix. Explore our laptop coverage for brands like Samsung and iPhone." /> : <MetaHead title="Comprehensive Device Insurance & Repair Services - PicknFix" description="Protect and repair your devices with PicknFix. Explore our laptop coverage, phone protection, and gadget insurance services for brands like Samsung and iPhone." />}
      {isUK ? <UkLanding /> : isPNF ? <TrialLanding /> : <IndexPage />}
    </>;
}
export default index;
export async function getStaticProps() {
  return {
    props: {}
  };
}