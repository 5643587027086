import Head from 'next/head';
import React from 'react';
type Props = {
  title: string;
  description: string;
};
const MetaHead = ({
  title,
  description
}: Props) => {
  return <Head data-sentry-element="Head" data-sentry-component="MetaHead" data-sentry-source-file="meta-head.tsx">
      <title>{title}</title>
      <meta name="description" content={description} data-sentry-element="meta" data-sentry-source-file="meta-head.tsx" />
    </Head>;
};
export default MetaHead;